/** @jsx jsx */

import Header from "../components/header"
import * as C from "../components/containers"
import * as H from "../components/headings"
import { jsx } from "theme-ui"


export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <div>
      <Header/>
      <C.centered>
        <H.h1>Blog</H.h1>
      </C.centered>
      <C.bioText sx={{ maxWidth: "1200px" }}>
        <C.centered>
          <H.h2>{frontmatter.title}</H.h2>
          <H.h4>{frontmatter.date}</H.h4>
        </C.centered>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </C.bioText>
    </div>
  )
}

export const pageQuery = graphql`
    query BlogPostByPath($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
            }
        }
    }
`